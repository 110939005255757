import React from 'react';

import { Hero, Callout, TextModule } from 'components';
import { DARK_COLORS } from '../styles/colors';

/**
 * Returns a React component.
 * @param {object} module The module properties.
 * @param {number} index Index of the module to be used as key.
 * @param {string} pageUrl The current page.
 */
export const renderComponents = (module, index, pageUrl) => {
  const {
    type,
    title,
    image,
    vimeoId,
    vimeoAutoplay,
    vimeoLoop,
    background,
    copy,
    columns,
  } = module;

  switch (type) {
    case 'hero':
      return (
        <Hero
          key={`module-${index}`}
          title={title}
          bgImage={image}
          vimeoId={vimeoId}
          vimeoAutoplay={vimeoAutoplay}
          vimeoLoop={vimeoLoop}
        />
      );
    case 'callout':
      return (
        <Callout
          key={`module-${index}`}
          message={copy}
          bgColor={background}
          theme={DARK_COLORS.includes(background) ? 'dark' : 'light'}
        />
      );
    case 'textModule':
      return (
        <TextModule
          key={`module-${index}`}
          title={title}
          bgColor={background}
          copy={copy}
          pageUrl={pageUrl}
          columns={columns}
        />
      );
    default:
      return <div id="" />;
  }
};
