// @flow
import React from 'react';
import { graphql } from 'gatsby';

import { PageTemplate } from 'components';

import { usePageUrl } from '../hooks/usePageUrl';
import { renderComponents } from '../utils/renderComponents';

type Props = {
  data: object,
};

const Page = ({ data }: Props) => {
  const { modules, title } = data.markdownRemark.frontmatter;
  const [pageUrl, setPageUrl] = usePageUrl(title);

  // To prevent loop and resetting initial.
  if (title !== pageUrl) {
    setPageUrl(title);
  }

  return (
    <PageTemplate>
      {modules.map((module, index) => renderComponents(module, index, pageUrl))}
    </PageTemplate>
  );
};

export default Page;

export const PageQuery = graphql`
  query PageQuery($id: String) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        modules {
          image
          type
          vimeoId
          vimeoAutoplay
          vimeoLoop
          background
          copy
          columns {
            copy
          }
          buttonLink
          buttonText
          title
        }
      }
    }
  }
`;
